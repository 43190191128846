<template>
  <div class="edit-users-component">
    <b-modal
      id="add-modal-edit-user"
      :lazy="true"
      centered
      :title="$t('edit_user')"
      modal-class="add-modal"
      title-class="add-modal-title"
      header-class="add-modal-header"
      footer-class="add-modal-footer"
      :ok-title-html="$t('g.edit')"
      :cancel-title="$t('cancel-button')"
      @ok="submitEditUser"
    >
      <validation-observer v-if="userData" ref="EditUserForm">
        <b-form>
          <b-row>
            <b-col cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('g.first_name')" label-for="name">
                <validation-provider #default="{ errors }" name="name">
                  <b-form-input
                    v-model="userData.first_name"
                    class="input-style"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>
            <b-col cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('g.last_name')" label-for="name">
                <validation-provider #default="{ errors }" name="name">
                  <b-form-input
                    v-model="userData.last_name"
                    class="input-style"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>
            <b-col cols="12">
              <!-- Start Dropdown -->
              <b-form-group :label="$t('g.roles')" label-for="roles">
                <validation-provider #default="{ errors }" name="roles">
                  <!-- <div class="roles-container">
                    <h6 v-for="(role, index) in selectedRole" :key="index">
                      {{
                        `${role.name}${
                          selectedRole.length !== index + 1 ? "," : ""
                        }`
                      }}
                    </h6>
                  </div> -->
                  <b-form-select
                    v-model="selectedRole"
                    class="input-style"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.users/edit_user/select_user')"
                  >
                    <b-form-select-option :value="null" :disabled="true">
                      {{ $t("g.users/edit_user/select_user_roles") }}
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="role in userRoles"
                      :key="role.id"
                      :value="role.id"
                    >
                      {{ role.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>
            <b-col cols="12">
              <!-- Start Input -->
              <b-form-group :label="$t('g.email')" label-for="email">
                <validation-provider #default="{ errors }" name="email">
                  <b-form-input
                    v-model="userData.email"
                    class="input-style"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>
            <b-col cols="12">
              <!-- Start Phone -->
              <b-form-group :label="$t('g.phone')" label-for="phone">
                <b-input-group>
                  <template #prepend>
                    <vue-country-code
                      ref="vcc"
                      class="input-style"
                      style="width: 80px"
                      :enabled-country-code="true"
                      :disabled="true"
                      default-country="sa"
                      :only-countries="['sa']"
                      :value="userData.country ? userData.country.code : null"
                    />
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    class="input-style phone-input"
                    style="width: calc(100% - 80px)"
                    name="phone"
                    rules="required|max:11|min:9"
                  >
                    <b-form-input
                      v-model="phone"
                      type="number"
                      class="input-style"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">
                      {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                    </small>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
              <!-- End Phone -->
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <OverlayComponent :is-loading="overlayLoading" />
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BRow,
  BCol,
  BModal,
  BForm,
} from "bootstrap-vue";
import VueCountryCode from "vue-country-code";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, password, email, max, min } from "@validations";

export default {
  name: "UsersComponent",
  components: {
    required,
    password,
    email,
    max,
    min,
    OverlayComponent,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    VueCountryCode,
    BRow,
    BCol,
    BModal,
    BForm,
  },
  props: ["userId"],
  data() {
    return {
      overlayLoading: true,
      userData: {},
      phone: null,
      userRoles: null,
      selectedRole: null,
    };
  },
  watch: {
    userId() {
      this.getUserData();
      this.getUserRoles();
    },
  },
  methods: {
    async getUserData() {
      try {
        this.overlayLoading = true;
        const res = await this.$http.get(`admin/users/${this.userId}`);
        this.userData = res.data.data;
        this.phone = res.data.data.phone?.[0].phone;
        this.selectedRole = res.data.data.roles?.web[0].id;
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    async getUserRoles() {
      try {
        this.overlayLoading = true;
        const res = await this.$http.get("admin/roles");
        const rolesCollector = [];
        for (const role of res.data.data) {
          rolesCollector.push({
            name: role.name,
            id: role.id,
          });
        }
        this.userRoles = rolesCollector;
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.overlayLoading = false;
      }
    },
    async submitEditUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.EditUserForm.validate().then(async (sucess) => {
        if (sucess) {
          try {
            this.overlayLoading = true;
            const formData = new FormData();
            const toEditdata = ["first_name", "last_name", "phone", "email"];
            for (const item in this.userData) {
              if (toEditdata.includes(item)) {
                if (item == "phone") {
                  formData.append(item, this.phone);
                } else {
                  formData.append(item, this.userData[item]);
                }
              }
            }
            formData.append(`role_id[0]`, this.selectedRole);
            const response = await this.$http.post(
              `admin/users/${this.userId}?_method=put`,
              formData
            );
            this.$helpers.makeToast(
              "success",
              response.data.message,
              response.data.message
            );
            this.$bvModal.hide("add-modal-edit-user");
          } catch (err) {
            this.$helpers.handleError(err);
          } finally {
            this.overlayLoading = false;
          }
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
