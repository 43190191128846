<template>
  <Loading v-if="isLoading" />
  <div v-else class="users-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">
          {{ $t("g.users/page_title") }}
        </h1>
        <div class="d-flex align-items-center">
          <AddUsersComponent
            v-if="globalPermission.users.store"
            @update-table="update"
          />
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        style-class="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span v-else-if="props.column.label === 'email'" class="text-nowrap">
            {{ $t("g.email") }}
          </span>
          <span
            v-else-if="
              props.column.label === 'address' &&
              globalPermission.users &&
              globalPermission.users.show
            "
            class="text-nowrap"
          >
            {{ $t("g.address") }}
          </span>
          <span
            v-else-if="
              props.column.label === 'is_blocked' &&
              globalPermission.users.update
            "
            class="text-nowrap"
          >
            {{ $t("g.is_blocked") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'name'"
            class="text-nowrap d-flex align-items-center"
          >
            <b-avatar
              variant="info"
              class="align-self-start mr-1"
              :src="props.row.image"
            />
            {{ props.row.name }}
          </span>
          <span
            v-else-if="props.column.field === 'address'"
            class="text-nowrap custom-toggle"
          >
            <span
              role="button"
              aria-pressed="true"
              class="view-button"
              @click="openAddressModal(props.row)"
            >
              {{
                props.row.addresses != ""
                  ? $t("g.view")
                  : $t("g.users/user_list/no_address")
              }}
            </span>
          </span>
          <span
            v-else-if="props.column.field === '#'"
            class="text-nowrap custom-toggle"
          >
            {{ props.row.index }}
          </span>
          <span
            v-else-if="props.column.field === 'is_blocked'"
            class="text-nowrap custom-toggle"
          >
            <b-form-checkbox
              :disabled="
                globalPermission.users && !globalPermission.users.update
              "
              v-model="props.row.is_blocked"
              switch
              @change="(e) => handleBlockUser(e, props.row.id)"
            />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="globalPermission.users && globalPermission.users.update"
                  @click="changeUserId(props.row.id)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="deleteUser(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <users-component :user-id="userId" />
    <overlay-component :is-loading="overlayLoading" />
    <users-address-component :page_data="page_data" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import dateTimeInFormat from "@/composables/format-date.js";
import Loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import UsersComponent from "@/components/UsersComponent/editUser/index.vue";
import AddUsersComponent from "@/components/UsersComponent/addUser/index.vue";
import UsersAddressComponent from "@/components/UsersComponent/address/UsersAddressComponent.vue";

export default {
  name: "UsersList",
  components: {
    AddUsersComponent,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
    UsersComponent,
    UsersAddressComponent,
  },
  data() {
    return {
      page_data: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      userId: null,
      formateDate: dateTimeInFormat,
      plusIcon: require("@/assets/images/icons/ic_round-add.svg"),
      tableHeader: [
        {
          label: "#",
          field: "#",
        },
        {
          label: "name",
          field: "name",
          sortable: false,
        },
        {
          label: "email",
          field: "email",
          sortable: false,
        },
        {
          label: "address",
          field: "address",
          sortable: false,
        },
        {
          label: "is_blocked",
          field: "is_blocked",
          sortable: false,
        },
        {
          label: "action",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    prermissions() {
      this.$store.dispatch("GET_PERMISSION", "users");
      return this.$store.state.userData.sub_Permission;
    },
  },
  beforeMount() {
    this.getUsersList();
  },

  created() {
    if (
      this.globalPermission.users &&
      !this.globalPermission.users.update &&
      this.globalPermission.users &&
      !this.globalPermission.users.destroy
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.field !== "action"
      );
    }
    if (this.globalPermission.users && !this.globalPermission.users.show) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.field !== "address"
      );
    }
  },
  methods: {
    async getUsersList() {
      try {
        const response = (await this.$http.get("admin/users")).data.data;
        this.tableBody = [response].flat().map((item, index) => ({
          index: index + 1,
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          phone_number: item?.phone[0]?.phone,
          type: item.type,
          user_name: item.user_name,
          birthdate: item.birthdate,
          created_at: item.created_at,
          is_blocked: item.is_blocked,
          addresses: item.addresses,
          image: item.media?.filter((ele) => ele?.name === "avatar")[0]?.path,
        }));
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    update() {
      this.getUsersList();
    },
    async deleteUser(userId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.$http.delete(`admin/users/${userId}`),
          "The_user_has_been_successfully_deleted"
        );
        this.overlayLoading = true;
        this.getUsersList();
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    async handleBlockUser(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_blocked: checked,
        };
        await this.$http.post(`admin/users/${id}?_method=put`, data);
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    changeUserId(userId) {
      this.$bvModal.show("add-modal-edit-user");
      this.userId = userId;
    },
    AddNewUser() {
      this.$bvModal.show("add-modal-user");
    },
    filterFn(data, filterString) {
      const x = parseInt(filterString);
      return data >= x - 5 && data <= x + 5;
    },
    async openAddressModal(data) {
      if (data.addresses.length) {
        await (this.page_data = data);
        this.$bvModal.show("address");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "./_index";
</style>
