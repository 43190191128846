var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-users-component"},[_c('b-modal',{attrs:{"id":"add-modal-edit-user","lazy":true,"centered":"","title":_vm.$t('edit_user'),"modal-class":"add-modal","title-class":"add-modal-title","header-class":"add-modal-header","footer-class":"add-modal-footer","ok-title-html":_vm.$t('g.edit'),"cancel-title":_vm.$t('cancel-button')},on:{"ok":_vm.submitEditUser}},[(_vm.userData)?_c('validation-observer',{ref:"EditUserForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.first_name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userData.first_name),callback:function ($$v) {_vm.$set(_vm.userData, "first_name", $$v)},expression:"userData.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,2219503864)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.last_name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userData.last_name),callback:function ($$v) {_vm.$set(_vm.userData, "last_name", $$v)},expression:"userData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3717806184)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.roles'),"label-for":"roles"}},[_c('validation-provider',{attrs:{"name":"roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.users/edit_user/select_user')},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":true}},[_vm._v(" "+_vm._s(_vm.$t("g.users/edit_user/select_user_roles"))+" ")]),_vm._l((_vm.userRoles),function(role){return _c('b-form-select-option',{key:role.id,attrs:{"value":role.id}},[_vm._v(" "+_vm._s(role.name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,2525978994)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}],null,false,3054346518)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.phone'),"label-for":"phone"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{ref:"vcc",staticClass:"input-style",staticStyle:{"width":"80px"},attrs:{"enabled-country-code":true,"disabled":true,"default-country":"sa","only-countries":['sa'],"value":_vm.userData.country ? _vm.userData.country.code : null}})]},proxy:true}],null,false,2036169176)},[_c('validation-provider',{staticClass:"input-style phone-input",staticStyle:{"width":"calc(100% - 80px)"},attrs:{"name":"phone","rules":"required|max:11|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-style",attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}],null,false,1572168432)})],1)],1)],1)],1)],1)],1):_vm._e(),_c('OverlayComponent',{attrs:{"is-loading":_vm.overlayLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }