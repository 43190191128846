<template>
  <b-modal
    id="address"
    size="lg"
    :title="$t('g.address_details')"
    centered
    hide-footer
    modal-class="address"
    v-if="page_data"
  >
    <div class="d-flex justify-content-center heading">
      <div class="box" v-for="address in page_data.addresses" :key="address.id">
        <a
          :style="{
            backgroundColor: address.is_primary ? '#2b79ad1f' : '#fff',
          }"
          :href="`http://maps.google.com/?q=${address.lat},${address.long}`"
          target="_blank"
        >
          <p>{{ address.address_name }}</p>
          <img src="@/assets/images/pages/address.svg" alt="address icon" />
        </a>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BRow, BCol, BButton, BModal } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
  },
  props: ["page_data"],
};
</script>

<style lang="scss">
@import "./_UsersAddressComponent.scss";
</style>
